a.btn.btn-primary, input.btn.btn-primary, button.btn.btn-primary,
.product-block .product-quantity .num .change-num.change-num-plus,
.page.page-inner .page-side .lang-switcher a,
.page.page-inner .page-container .page-heading .heading-l.heading-yellow{
  background-color:#000000 !important;
}
.page.page-inner .page-container .page-heading .heading-r a.heading-cart.nonempty
{
  background:none !important;
}

.card-track{
  box-shadow: 0px 10px 14px 2px #D3D1D4;
}
.search{
  border: solid 1px #B1ADB4;
  box-shadow: 0px 11px 12px #D3D1D4;
}
.main-track-circle{
  box-shadow: 0px 0px 5px #D3D1D4;
}
.inner-track-circle{
  box-shadow: 0px 0px 5px #D3D1D4;
}
.contact-border-green{
  border-bottom: 1px solid #000000;
}
a:hover{
  color:#000000;
}
a.btn.btn-primary:hover, input.btn.btn-primary:hover, button.btn.btn-primary:hover,
.product-block .product-quantity .num .change-num.change-num-plus:hover,
.page.page-inner .page-container .page-btn.page-btn-checkout .total{
  background-color:#000000 !important;
}
.page.page-inner .page-container .page-heading .heading-r a.heading-cart.nonempty:hover
{
  background-color:none !important;
  background:none !important;

}

.sb-default .sb-wrapper.sb-copy{
  background: #000000 !important;
}


a.btn.btn-danger, input.btn.btn-danger, button.btn.btn-danger
{
  background-color:rgb(161, 40, 40);
  color:white;
}

a.btn.btn-danger:hover, input.btn.btn-danger:hover, button.btn.btn-danger:hover
{
  background-color:rgb(168, 33, 33);
  color:white;

}



.product-block .product-options .product-options-heading .clear a
{
  color:#000000
}

.product-block .product-options .product-options-heading .clear a:hover
{
  color:#000000
}

.checkbox:checked + label:after
{
  background-color:#000000
}
.checkbox + label:before {
  border-color:#000000
}

.bg-primary
{
  background-color:#000000 !important;
}
.color-primary{
  color:#000000 !important;
}

.page.page-inner .page-container .page-heading .heading-r a.heading-cart i.im.im-round {
  border-color:#000000
}

.page-side-inner a {
  background:none !important;
  color:white;
}
.card-counts{
  background: #000000;
  box-shadow: 0 0px 4px 0 #000000;
}

.radio:checked + label:after {
  background: #000000;
}
button.bg-primary:hover
{
  background: #000000 !important;
}

.intl-tel-input .selected-flag .iti-arrow {
  border-top: 9px solid #000000 !important;
}
.card-counts{
  background: #000000;
  box-shadow: 0 0px 4px 0 #000000;
}
.text-shadow{
  text-shadow: 2px 2px 3px black;
}


.lds-ellipsis div {
  background: #000000;
}

.cart-icon path{
  fill:#000000 !important;
}
.comp-substitution .governate .radio + label:before{
  border:1.5px solid #79d600;

}
